<template>
  <div class="default">
    <!-- 面包屑 -->
    <div class="crumb">
      <Breadcrumb separator=">">
        <Breadcrumb-item :to="{ path: '/' }">悦心车</Breadcrumb-item>
        <Breadcrumb-item :to="{ path: '/carlist' }">车辆列表</Breadcrumb-item>
        <Breadcrumb-item :to="{ path: '/detail' }">车辆详情</Breadcrumb-item>
      </Breadcrumb>
    </div>
    <!-- banner -->
    <div class="banner">
      <div class="left">
        <div class="big-img">
          <img :src="baseurl + bigimg" alt />
        </div>
        <div class="img-small-box">
          <Carousel arrow="always">
            <Carousel-item v-for="(item, index) in banners" :key="index">
              <div class="demo-carousel">
                <div
                  class="demo"
                  v-for="(e, i) in item"
                  :key="i"
                  @click="chooseImg(e)"
                >
                  <img :src="baseurl + e" alt />
                </div>
              </div>
            </Carousel-item>
          </Carousel>
        </div>
      </div>
      <div class="right">
        <div class="title">
          {{ cardetail.brand_name }} {{ cardetail.car_name }}
          {{ cardetail.displacement }}
        </div>
        <div class="info-wraper">
          <div class="info-box">
            <div class="info">
              <p class="info-title-msg">
                {{ cardetail.register_time || "暂无数据" }}
              </p>
              <p class="info-title-title">上牌时间</p>
            </div>
            <div class="info">
              <p class="info-title-msg">
                {{ cardetail.travel_km || "暂无数据" }}
              </p>
              <p class="info-title-title">鉴定里程</p>
            </div>
            <div class="info">
              <p class="info-title-msg">
                {{ cardetail.displacement || "暂无数据" }}
              </p>
              <p class="info-title-title">排量</p>
            </div>
            <div class="info">
              <p class="info-title-msg">
                {{ cardetail.gearbox || "暂无数据" }}
              </p>
              <p class="info-title-title">变速箱</p>
            </div>
          </div>
        </div>
        <div class="price-wraper">
          <div>
            车价
            <!-- <span>{{ cardetail.sale_price | numFilter }}</span -->
            <span>{{ allprice | numFilter }}</span
            >万
            <!-- +整备服务费{{cardetail.outfit_price | numFilter }}万 -->
          </div>
          <div>
            原新车价
            <span>{{ cardetail.price | numFilter }}</span
            >万   为您节省约{{cardetail.price - cardetail.sale_price  | numFilter }}万
          </div>
        </div>

        <div class="service-wraper">
          <div class="service-title">悦心服务</div>
          <div class="service-box">
            <!-- <div class="service-item" v-if="cardetail.is_no_reason">
              <div class="service">
                <div class="service-icon"></div>
                <div class="service-msg">7天无理由退车</div>
              </div>
            </div> -->

            <div
              class="service-item"
              v-for="(item, index) in service"
              :key="index"
            >
              <div class="service">
                <div class="service-icon"></div>
                <div class="service-msg">{{ item.msg }}</div>
              </div>
            </div>
          </div>
          <div class="btn-box-wraper">
            <div class="btn-box">
              <div class="booking" @click="reservation = true">预约顾问</div>
              <div class="checking" v-if="true" @click="priceDialog = true">
                查看金融解决方案
              </div>
              <div class="checking" v-if="false" @click="elepriceDialog = true">
                查看金融解决方案
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 预约弹框 -->
    <div class="reservation-dialog">
      <el-dialog title="提示" :visible.sync="reservation" width="800px">
        <div class="report-header" slot="title">
          <div class="report-header-msg" style="font-size: 18px;">
            我将竭诚为您解答购车疑问
          </div>
        </div>
        <div class="popup-commen">
          <div class="popup-commen-input-box">
            <!-- 位置 -->
            <div class="popup-commen-input-list">
              <div class="popup-commen-input-list-name">
                <div class="popup-commen-input-list-name-line"></div>
                <div class="popup-commen-input-list-name-msg">城市服务</div>
              </div>
              <div class="popup-commen-input-list-con">
                <input
                  type="text"
                  value
                  v-model="city"
                  placeholder="请输入您所在的城市"
                />
              </div>
            </div>
            <!-- 电话 -->
            <div class="popup-commen-input-list">
              <div class="popup-commen-input-list-name">
                <div class="popup-commen-input-list-name-line"></div>
                <div class="popup-commen-input-list-name-msg">联系电话</div>
              </div>
              <div class="popup-commen-input-list-con">
                <input
                  type="text"
                  v-model="clientTel"
                  placeholder="请输入您的联系方式"
                />
              </div>
            </div>
            <!-- 时间 -->
            <div class="popup-commen-input-time-wraper">
              <div class="popup-commen-input-time-title">
                <div class="popup-commen-input-time-title-line"></div>
                <div class="popup-commen-input-time-title-msg">预约时间</div>
              </div>
              <div class="popup-commen-input-time-day-box">
                <div
                  class="popup-commen-input-time-day-list"
                  :class="currentDay == index ? 'hot' : ''"
                  v-for="(item, index) in day"
                  :key="index"
                  @click="chooseDay(item, index)"
                >
                  {{ item }}
                </div>
              </div>
              <div class="popup-commen-input-time-hour-box">
                <div
                  class="popup-commen-input-time-hour-list"
                  :class="currentHour == index ? 'hot' : ''"
                  v-for="(item, index) in time"
                  :key="index"
                  @click="chooseHour(item, index)"
                >
                  <div class="popup-commen-input-time-hour-list-halfday">
                    {{ item.halfday }}
                  </div>
                  <div class="popup-commen-input-time-hour-list-hour">
                    {{ item.hour }}
                  </div>
                </div>
              </div>
              <div class="popup-commen-input-time-submit-box">
                <div
                  class="popup-commen-input-time-submit"
                  @click="addOrderRecForPc"
                >
                  提交反馈
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

    <!-- priceDialog -->
    <div class="price-dialog">
      <el-dialog :visible.sync="priceDialog" width="1000px">
        <div class="header" slot="title">
          <div
            class="title-txt-box"
            :class="active == index ? 'hot' : ''"
            v-for="(item, index) in priceHead"
            :key="index"
            @click="choosePriceServer(index)"
          >
            {{ item }}
            <div class="line"></div>
          </div>
        </div>
        <!-- 金融服务方案 -->
        <div class="price" v-if="priceServer">
          <div class="all-price">当前总价：￥{{ allprice | numFilter }}万</div>
          <div class="item-tem" v-if="!cardetail.is_ne_car">
            <div class="left">安心无忧保修服务（可选）</div>
            <div class="right">
              <div
                class="extension-server"
                :class="serverNum == index ? 'hot' : ''"
                v-for="(item, index) in server"
                :key="index"
                @click="chooseServer(index)"
              >
                <div class="price-wraper-t">
                  <div class="extension-server-name">{{ item.info }}</div>
                  <div class="extension-server-price">{{ item.price }}</div>
                </div>
                <div
                  class="extension-server-icon"
                  @click.stop="showDialog(index)"
                >
                  <img src="@/static/detail/price_icon.png" alt />
                </div>
              </div>
            </div>
          </div>
          <div class="item-tem">
            <div class="left">选择首付方式</div>
            <div class="right">
              <div
                class="choose-payment-list"
                :class="index == paymentNum ? 'hot' : ''"
                v-for="(item, index) in payment"
                :key="index"
                @click="choosePayment(item, index)"
              >
              
                {{ item }}
              </div>
            </div>
          </div>
          <div class="item-tem">
            <div class="left">选择分期付款方式</div>
            <div class="right">
              <div
                class="choose-stages-list"
                :class="index == stagesNum ? 'hot' : ''"
                v-for="(item, index) in stages"
                :key="index"
                @click="chooseStages(item, index)"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="item-tems">
            <div
              class="repayment-list"
              v-for="(item, index) in repaymentshow"
              :key="index"
              v-show="repaymentshow[0].num"
            >
              <div class="repayment-list-left">{{ item.num }}</div>
              <div class="repayment-list-left-line"></div>
              <div class="repayment-list-right">
                {{ item.msg }}
                <span style="margin-left: 5px;"
                  >{{ item.price | filterNumber }}/月</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- 以租代售 -->
        <div class="rent" v-if="!priceServer">
          <div class="rent-box">
            <div class="car-box">
              <div class="title">车辆信息</div>
              <div class="car-name">
                {{ cardetail.brand_name }} {{ cardetail.car_name }}
                {{ cardetail.displacement }}
              </div>
              <div class="car-price">
                月租：
                <span>￥{{ cardetail.rental | filterNumber }}</span
                >元起
              </div>
            </div>
            <!-- <div >
              <img style="margin:0 auto;display:block" src="https://www.yuexinche.com/pic/14.png" alt="">
            </div>-->
            <div class="ex">
              <div class="ex-title">代售流程</div>
              <div class="ex-lists">
                <div class="ex-list">
                  <div class="img-box">
                    <img src="@/static/detail/rent1.png" alt />
                  </div>
                  <div class="info">
                    <div class="info-title">在线选车</div>
                    <div class="info-msg">通过悦心车及小程序在线选车</div>
                  </div>
                </div>
                <div class="ex-list">
                  <div class="img-box">
                    <img src="@/static/detail/rent2.png" alt />
                  </div>
                  <div class="info">
                    <div class="info-title">制定方案</div>
                    <div class="info-msg">拨打4009975955或在线制定租车方案</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ex">
              <div class="ex-title">优势</div>
              <div class="ex-lists">
                <div class="ex-list">
                  <div class="img-box">
                    <img src="@/static/detail/rent3.png" alt />
                  </div>
                  <div class="info">
                    <div class="info-title">价格更低</div>
                    <div class="info-msg">悦心车提供高端好车的租赁服务</div>
                  </div>
                </div>
                <div class="ex-list">
                  <div class="img-box">
                    <img src="@/static/detail/rent4.png" alt />
                  </div>
                  <div class="info">
                    <div class="info-title">租期灵活</div>
                    <div class="info-msg">短租更灵活，长租更划算</div>
                  </div>
                </div>
                <div class="intro">（*不同车型具体租赁方案联系客服核算）</div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

    <!-- elepriceDialog -->
    <div class="price-dialog">
      <el-dialog :visible.sync="elepriceDialog" width="1000px">
        <div class="header" slot="title">
          <div
            class="title-txt-box"
            :class="active == index ? 'hot' : ''"
            v-for="(item, index) in priceHead"
            :key="index"
            @click="elechoosePriceServer(index)"
          >
            {{ item }}
            <div class="line"></div>
          </div>
        </div>
        <!-- 金融服务方案 -->
        <div class="price" v-if="elepriceServer">
          <div class="all-price">当前总价：￥{{ allprice | numFilter }}万</div>
          <div class="item-tem">
            <div class="left">安心无忧保修服务</div>
            <div class="right">
              <div
                class="extension-server"
                :class="serverNum == index ? 'hot' : ''"
                v-for="(item, index) in eleserver"
                style="width:60%"
                :key="index"
                @click="elechooseServer(index)"
              >
                <div class="price-wraper-t">
                  <div class="extension-server-name">{{ item.name }}</div>
                  <div class="extension-server-price">{{ item.price }}</div>
                </div>
                <div
                  class="extension-server-icon"
                  @click.stop="eleshowDialog(index)"
                >
                  <img src="@/static/detail/price_icon.png" alt />
                </div>
              </div>
            </div>
          </div>
          <div class="item-tem">
            <div class="left">选择首付方式</div>
            <div class="right">
              <div
                class="choose-payment-list"
                :class="index == paymentNum ? 'hot' : ''"
                v-for="(item, index) in payment"
                :key="index"
                @click="elechoosePayment(item, index)"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="item-tem">
            <div class="left">选择分期付款方式</div>
            <div class="right">
              <div
                class="choose-stages-list"
                :class="index == stagesNum ? 'hot' : ''"
                v-for="(item, index) in stages"
                :key="index"
                @click="elechooseStages(item, index)"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="item-tems">
            <div
              class="repayment-list"
              v-for="(item, index) in repaymentshow"
              :key="index"
              v-show="repaymentshow[0].num"
            >
              <div class="repayment-list-left">{{ item.num }}</div>
              <div class="repayment-list-left-line"></div>
              <div class="repayment-list-right">
                {{ item.msg }}
                <span style="margin-left: 5px;"
                  >{{ item.price | filterNumber }}/月</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- 以租代售 -->
        <div class="rent" v-if="!elepriceServer">
          <div class="rent-box">
            <div class="car-box">
              <div class="title">车辆信息</div>
              <div class="car-name">
                {{ cardetail.brand_name }} {{ cardetail.car_name }}
                {{ cardetail.displacement }}
              </div>
              <div class="car-price">
                月租：
                <span>￥{{ cardetail.rental | filterNumber }}</span
                >元起
              </div>
            </div>
            <!-- <div >
              <img style="margin:0 auto;display:block" src="https://www.yuexinche.com/pic/14.png" alt="">
            </div>-->
            <div class="ex">
              <div class="ex-title">代售流程</div>
              <div class="ex-lists">
                <div class="ex-list">
                  <div class="img-box">
                    <img src="@/static/detail/rent1.png" alt />
                  </div>
                  <div class="info">
                    <div class="info-title">在线选车</div>
                    <div class="info-msg">通过悦心车及小程序在线选车</div>
                  </div>
                </div>
                <div class="ex-list">
                  <div class="img-box">
                    <img src="@/static/detail/rent2.png" alt />
                  </div>
                  <div class="info">
                    <div class="info-title">制定方案</div>
                    <div class="info-msg">拨打4009975955或在线制定租车方案</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ex">
              <div class="ex-title">优势</div>
              <div class="ex-lists">
                <div class="ex-list">
                  <div class="img-box">
                    <img src="@/static/detail/rent3.png" alt />
                  </div>
                  <div class="info">
                    <div class="info-title">价格更低</div>
                    <div class="info-msg">悦心车提供高端好车的租赁服务</div>
                  </div>
                </div>
                <div class="ex-list">
                  <div class="img-box">
                    <img src="@/static/detail/rent4.png" alt />
                  </div>
                  <div class="info">
                    <div class="info-title">租期灵活</div>
                    <div class="info-msg">短租更灵活，长租更划算</div>
                  </div>
                </div>
                <div class="intro">（*不同车型具体租赁方案联系客服核算）</div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

    <!-- 车辆档案 -->
    <div class="files">
      <div class="commen-title">
        <div class="commen-box">
          <div class="left">
            <div class="left-sign"></div>
            <div class="left-title">车辆档案</div>
          </div>
          <div class="right" @click="goMoreParmas">
            <div class="right-title">查看更多参数</div>
            <div class="right-sign">></div>
          </div>
        </div>
      </div>

      <div class="files-info-wraper">
        <div class="files-info">
          <div class="info-msg">
            {{ cardetail.register_time || "暂无数据" }}
          </div>
          <div class="info-title">上牌时间</div>
        </div>
        <div class="files-info">
          <div class="info-msg">{{ cardetail.travel_km || "暂无数据" }}</div>
          <div class="info-title">鉴定里程</div>
        </div>
        <div class="files-info">
          <div class="info-msg">{{ cardetail.emission || "暂无数据" }}</div>
          <div class="info-title">排放标准</div>
        </div>
        <div class="files-info">
          <div class="info-msg">{{ cardetail.displacement || "暂无数据" }}</div>
          <div class="info-title">排量</div>
        </div>
        <div class="files-info">
          <div class="info-msg">{{ cardetail.gearbox || "暂无数据" }}</div>
          <div class="info-title">变速箱</div>
        </div>
        <div class="files-info">
          <div class="info-msg">
            {{ cardetail.license_plate || "暂无数据" }}
          </div>
          <div class="info-title">牌照地</div>
        </div>
      </div>

      <div class="files-table">
        <div class="table">
          <div class="table-title">基本参数</div>
          <i-table
            border
            :show-header="false"
            stripe
            :columns="columns1"
            :data="data1"
          ></i-table>
        </div>
        <div class="table">
          <div class="table-title">发动机参数</div>
          <i-table
            border
            :show-header="false"
            stripe
            :columns="columns1"
            :data="data2"
          ></i-table>
        </div>
        <div class="table">
          <div class="table-title">底盘及制动</div>
          <i-table
            border
            :show-header="false"
            stripe
            :columns="columns1"
            :data="data3"
          ></i-table>
        </div>
      </div>
    </div>

    <!-- 严格品控 -->
    <Taceability v-if="true"></Taceability>
    <eleTaceability v-if="false"></eleTaceability>

    <!-- 质量承诺 -->
    <Quailty :qdialog="qdialog" :isEle="cardetail.is_ne_car"></Quailty>
    <!-- <eleQuailty v-else></eleQuailty> -->

    <!-- 车辆图片 -->
    <div class="carimg">
      <div class="commen-title">
        <div class="commen-box">
          <div class="left">
            <div class="left-sign"></div>
            <div class="left-title">车辆图片</div>
          </div>
        </div>
      </div>
      <div class="imgshow">
        <div
          class="img-item"
          v-for="(item, index) in fourcarimg"
          :key="index"
          :style="[
            {
              backgroundImage: 'url(' + baseurl + item + ')',
            },
          ]"
        >
          <!-- <img :src="baseurl + item" alt /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Quailty from "./quailty/index";
// import eleQuailty from "./ele-quailty/index";
import Taceability from "./traceability/index";
import eleTaceability from "./ele-traceability/index";
import { carDetail, getSpecParams, addOrderRecForPc } from "@/api/index.js";
export default {
  components: {
    // eleQuailty,
    Quailty,
    eleTaceability,
    Taceability,
  },

  data() {
    return {
      qdialog:'-1',
      // 预约弹框
      day: [],
      currentDay: 0,
      // 所在城市
      city: "",
      // 客户电话
      clientTel: "",
      // 预约时间
      orderDay: "",
      // 预约上午下午
      orderHour: "",
      time: [
        {
          halfday: "上午",
          hour: "8:00—12:00",
        },
        {
          halfday: "下午",
          hour: "12:00—18:00",
        },
      ],
      currentHour: 0,
      baseurl: "https://www.yuexinche.com",
      // 车辆详情
      cardetail: {},
      // 图片
      carimgs: [],
      fourcarimg: [],
      bigimg: "",
      priceServer: true,
      priceDialog: false,
      elepriceServer: true,
      elepriceDialog: false,
      priceHead: ["金融服务方案"], //["金融服务方案", "以租代售方案"],
      active: 0,
      current: -1,
      price: 20.8,
      allprice: "",
      serverNum: -1,
      server: [
        {
          info: "安心无忧基础版",
          price: "￥2980",
        },
        {
          info: "安心无忧尊享版",
          price: "车价4%",
        },
      ],
      eleserver: [
        {
          name: "安心无忧（绿色版）",
          price: "车价4%",
        },
      ],
      // 首付
      paymentNum: -1,
      payment: ["0首付", "3成首付", "5成首付", "7成首付"],
      // 分期
      stagesNum: -1,
      stages: ["12期", "24期", "36期", "48期"],
      // 还款
      repayment: [
        {
          num: 1,
          msg: "第一年还款计划：¥",
          price: 102.4,
        },
        {
          num: 2,
          msg: "第二年还款计划：¥",
          price: 102.4,
        },
        {
          num: 3,
          msg: "第三年还款计划：¥",
          price: 102.4,
        },
        {
          num: 4,
          msg: "第四年还款计划：¥",
          price: 102.4,
        },
      ],
      repaymentshow: [
        {
          num: "",
          msg: "",
          price: "",
        },
      ],
      banners: [],
      service: [
        { icon: "", msg: "终身客服 " },
        { icon: "", msg: "机场代泊 " },
        { icon: "", msg: "代办保养 " },
        { icon: "", msg: "深度试驾 " },
        { icon: "", msg: "代办年检 " },
        { icon: "", msg: "道路救援 " },
        { icon: "", msg: "协助保险出险理赔 " },
        { icon: "", msg: "保养、限号代步车 " },
      ],
      columns1: [
        {
          title: "",
          key: "k",
        },
        {
          title: "",
          key: "v",
        },
      ],
      data2: [],
      data3: [],
      data1: [],
      id: "",
      reservation: false,
    };
  },
  created() {
    this.id = this.$route.query.id;
    // gettime
    this.gettime();
    this.chooseDay();
    this.chooseHour();
  },
  mounted() {
    // this.price = Option.salePrice
    // this.id = Option.id
    // uni.setStorage({
    //   key: 'price',
    //   data: this.price
    // })
    this.carDetail(); //车辆详情
    this.getSpecParams(); // 车辆配置参数
    // this.repairPrices(); // 车辆分期价格
  },
  filters: {
    filterNumber(value) {
      if (!value) return "0.00";
      var intPart = Number(value).toFixed(0); //获取整数部分
      var intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); //将整数部分逢三一断
      var floatPart = ""; //预定义小数部分
      var value2Array = value.toString().split(".");
      //=2表示数据有小数位
      if (value2Array.length == 2) {
        floatPart = value2Array[1].toString(); //拿到小数部分
        if (floatPart.length == 1) {
          //补0,实际上用不着
          return intPartFormat + "." + "0";
        } else {
          return intPartFormat + ".";
        }
      } else {
        return intPartFormat;
      }
    },
    numFilter(value) {
      let realVal = "";
      if (!isNaN(value) && value !== "") {
        // 截取当前数据到小数点后三位
        let tempVal = parseFloat(value).toFixed(3);
        realVal = tempVal;
        // realVal = tempVal.substring(0, tempVal.length - 1);
        if (tempVal.substring(tempVal.length - 1) == "0") {
          let tempVal1 = parseFloat(value).toFixed(2);
          realVal = tempVal1;
        }
      } else {
        realVal = "–";
      }
      return realVal;
    },
  },
  watch: {
    $route() {
      let query = this.$route.query.id;
      if(query){
        this.$router.go(0)
      }
    },
  },
  methods: {
    // //车辆分期价格   //改版添加到车辆详情里
    // repairPrices() {
    //   repairPrices({
    //     id: this.id,
    //   }).then((res) => {
    //     console.log("---------车辆分期------------", res.data);
    //     this.server = res.data
    //   });
    // },
    // gettime
    gettime() {
      let arr = [];
      for (let i = 1; i < 6; i++) {
        const aa = Date.parse(new Date()) - 86400000;
        const date = new Date(aa + 86400000 * i);
        const mon = date.getMonth() + 1;
        const day = date.getDate();
        arr.push(mon + "月" + day + "日");
      }
      this.day = arr;
      console.log(this.day);
    },
    // 选择day
    chooseDay(item, index) {
      this.currentDay = index ? index : "0";
      this.orderDay = this.day[index == undefined ? "0" : index];
    },
    // 选择上午下午
    chooseHour(item, index) {
      // const month = new Date().getMonth() + 1;
      // const today = new Date().getDate();
      // const hour = new Date().getHours();
      // const now = month + "." + today;
      this.currentHour = index ? index : "0";
      // console.log(now,this.day[0])
      // if(hour >= 12 && this.day[0] == now){
      // 	this.currentHour == 1
      // }else{
      // 	this.currentHour = index
      // }
      this.orderHour = this.time[index == undefined ? "0" : index];
    },
    // 提交预约
    addOrderRecForPc() {
      const reg = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/;
      if (!reg.test(this.clientTel)) {
        return this.$Message.warning("请输入正确的手机号");
      }
      addOrderRecForPc({
        // open_id: uni.getStorageSync("openid"),
        mobile: this.clientTel,
        city: this.city,
        order_time:
          this.orderDay + this.orderHour.halfday + this.orderHour.hour,
      }).then((res) => {
        if (res.code == 0) {
          this.$Message.success(res.msg);
          this.reservation = false;
          this.clientTel == "";
          this.city;
        } else {
          this.$Message.warning("请填写完整信息");
        }
      });
    },
    // 车辆配置参数
    getSpecParams() {
      getSpecParams({
        id: this.id,
      }).then((res) => {
        this.data1 = res.data.basic_items.slice(0, 10);
        this.data2 = res.data.engine.slice(0, 10);
        this.data3 = res.data.chassis.concat(res.data.wheel_brake).slice(0, 10);
      });
    },
    // 车辆详情
    carDetail() {
      carDetail({
        id: this.id,
      }).then((res) => {
        console.log("---------车辆详情------------", res.data);
        this.cardetail = res.data;
        for (var i = 0; i < res.data.car_pic.split(",").length; i += 5) {
          this.banners.push(res.data.car_pic.split(",").slice(i, i + 5));
        } //轮播图
        this.bigimg = res.data.car_pic.split(",")[0]; //bigimg
        this.fourcarimg = res.data.car_pic.split(","); //底部四张图片
        this.price = res.data.sale_price;
        this.allprice = this.price;
        this.server[0].price = res.data.server_price;
        this.server = res.data.repair_price
      });
    },
    // 大图
    chooseImg(imgUrl) {
      this.bigimg = imgUrl;
    },
    // 查看更多参数
    goMoreParmas() {
      this.$router.push({
        path: "/detail/configure",
        query: {
          brand: this.cardetail.brand_name,
          car: this.cardetail.car_name,
          id: this.id,
        },
      });
    },
    // 小程序dialog
    showDialog(index) {
      this.qdialog= index
    },
    eleshowDialog(index){
      this.qdialog= index
    },
    // 金融服务
    choosePriceServer(index) {
      this.active = index;
      if (index == 0) {
        this.priceServer = true;
      } else {
        this.priceServer = false;
      }
    },
    // 选择版本
    async chooseServer(index) {
      if (this.serverNum == index) {
        this.current = -1;
        this.paymentNum = -1;
        this.stagesNum = -1;
        // const value = uni.getStorageSync('price')
        // this.allprice = Number(this.price)
        await this.priceJudge();
      } else {
        this.serverNum = index;
        // const value = uni.getStorageSync('price')
        if (index != -1) {
          this.allprice =
            Number(this.price) + Number(this.server[index].price) / 10000;
            debugger
        } else {
          this.allprice =
            Math.round(Number(this.price) * (1 + 0.04) * 10000) / 10000;   //第一版本第二个是*0.04的  后面改成客户自己编辑的
        }
        await this.priceJudge();
      }
      // uni.setStorageSync('detailsprice', this.price);
    },
    // 选择首付
    choosePayment(item, index) {
      if (this.paymentNum == index) {
        this.paymentNum = -1;
        this.stagesNum = -1;
        console.log(this.stagesNum, "分期index");
        console.log(this.paymentNum, "首付index");
        console.log(this.allprice, "当前总价");
        this.priceJudge();
      } else {
        this.paymentNum = index;
        console.log(this.stagesNum, "分期index");
        console.log(this.paymentNum, "首付index");
        console.log(this.allprice, "当前总价");
        this.priceJudge();
      }
    },
    // 选择分期
    chooseStages(item, index) {
      if (this.stagesNum == index) {
        this.stagesNum = -1;
        console.log(this.stagesNum, "分期index");
        console.log(this.paymentNum, "首付index");
        console.log(this.allprice, "当前总价");
        this.priceJudge();
      } else {
        this.stagesNum = index;
        console.log(this.stagesNum, "分期index");
        console.log(this.paymentNum, "首付index");
        console.log(this.allprice, "当前总价");
        this.priceJudge();
      }
    },
    // 价格判断
    priceJudge() {
      if (this.paymentNum == 0) {
        if (this.stagesNum == 0) {
          this.onePay = Math.round(Number(this.allprice) * 879.11);
          this.repayment[0].price = this.onePay;
          this.repaymentshow = this.repayment.slice(0, 1);
        } else if (this.stagesNum == 1) {
          this.onePay = Math.round(Number(this.allprice) * 461.45);
          this.twoPay = Math.round(Number(this.allprice) * 461.45);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repaymentshow = this.repayment.slice(0, 2);
        } else if (this.stagesNum == 2) {
          this.onePay = Math.round(Number(this.allprice) * 335.36);
          this.twoPay = Math.round(Number(this.allprice) * 335.36);
          this.thrPay = Math.round(Number(this.allprice) * 293.01);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repayment[2].price = this.thrPay;
          this.repaymentshow = this.repayment.slice(0, 3);
        } else if (this.stagesNum == 3) {
          this.onePay = Math.round(Number(this.allprice) * 272.39);
          this.twoPay = Math.round(Number(this.allprice) * 272.39);
          this.thrPay = Math.round(Number(this.allprice) * 240.62);
          this.fourPay = Math.round(Number(this.allprice) * 219.79);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repayment[2].price = this.thrPay;
          this.repayment[3].price = this.fourPay;
          this.repaymentshow = this.repayment;
        } else {
          this.repaymentshow = {};
        }
      } else if (this.paymentNum == 1) {
        if (this.stagesNum == 0) {
          this.onePay = Math.round(Number(this.allprice) * 0.7 * 879.11);
          this.repayment[0].price = this.onePay;
          this.repaymentshow = this.repayment.slice(0, 1);
        } else if (this.stagesNum == 1) {
          this.onePay = Math.round(Number(this.allprice) * 0.7 * 461.45);
          this.twoPay = Math.round(Number(this.allprice) * 0.7 * 461.45);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repaymentshow = this.repayment.slice(0, 2);
        } else if (this.stagesNum == 2) {
          this.onePay = Math.round(Number(this.allprice) * 0.7 * 335.36);
          this.twoPay = Math.round(Number(this.allprice) * 0.7 * 335.36);
          this.thrPay = Math.round(Number(this.allprice) * 0.7 * 293.01);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repayment[2].price = this.thrPay;
          this.repaymentshow = this.repayment.slice(0, 3);
        } else if (this.stagesNum == 3) {
          this.onePay = Math.round(Number(this.allprice) * 0.7 * 272.39);
          this.twoPay = Math.round(Number(this.allprice) * 0.7 * 272.39);
          this.thrPay = Math.round(Number(this.allprice) * 0.7 * 240.62);
          this.fourPay = Math.round(Number(this.allprice) * 0.7 * 219.79);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repayment[2].price = this.thrPay;
          this.repayment[3].price = this.fourPay;
          this.repaymentshow = this.repayment;
        } else {
          this.repaymentshow = {};
        }
      } else if (this.paymentNum == 2) {
        if (this.stagesNum == 0) {
          this.onePay = Math.round(Number(this.allprice) * 0.5 * 879.11);
          this.repayment[0].price = this.onePay;
          this.repaymentshow = this.repayment.slice(0, 1);
        } else if (this.stagesNum == 1) {
          this.onePay = Math.round(Number(this.allprice) * 0.5 * 461.45);
          this.twoPay = Math.round(Number(this.allprice) * 0.5 * 461.45);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repaymentshow = this.repayment.slice(0, 2);
        } else if (this.stagesNum == 2) {
          this.onePay = Math.round(Number(this.allprice) * 0.5 * 335.36);
          this.twoPay = Math.round(Number(this.allprice) * 0.5 * 335.36);
          this.thrPay = Math.round(Number(this.allprice) * 0.5 * 293.01);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repayment[2].price = this.thrPay;
          this.repaymentshow = this.repayment.slice(0, 3);
        } else if (this.stagesNum == 3) {
          this.onePay = Math.round(Number(this.allprice) * 0.5 * 272.39);
          this.twoPay = Math.round(Number(this.allprice) * 0.5 * 272.39);
          this.thrPay = Math.round(Number(this.allprice) * 0.5 * 240.62);
          this.fourPay = Math.round(Number(this.allprice) * 0.5 * 219.79);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repayment[2].price = this.thrPay;
          this.repayment[3].price = this.fourPay;
          this.repaymentshow = this.repayment;
        } else {
          this.repaymentshow = {};
        }
      } else if (this.paymentNum == 3) {
        if (this.stagesNum == 0) {
          this.onePay = Math.round(Number(this.allprice) * 0.3 * 879.11);
          this.repayment[0].price = this.onePay;
          this.repaymentshow = this.repayment.slice(0, 1);
        } else if (this.stagesNum == 1) {
          this.onePay = Math.round(Number(this.allprice) * 0.3 * 461.45);
          this.twoPay = Math.round(Number(this.allprice) * 0.3 * 461.45);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repaymentshow = this.repayment.slice(0, 2);
        } else if (this.stagesNum == 2) {
          this.onePay = Math.round(Number(this.allprice) * 0.3 * 335.36);
          this.twoPay = Math.round(Number(this.allprice) * 0.3 * 335.36);
          this.thrPay = Math.round(Number(this.allprice) * 0.3 * 293.01);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repayment[2].price = this.thrPay;
          this.repaymentshow = this.repayment.slice(0, 3);
        } else if (this.stagesNum == 3) {
          this.onePay = Math.round(Number(this.allprice) * 0.3 * 272.39);
          this.twoPay = Math.round(Number(this.allprice) * 0.3 * 272.39);
          this.thrPay = Math.round(Number(this.allprice) * 0.3 * 240.62);
          this.fourPay = Math.round(Number(this.allprice) * 0.3 * 219.79);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repayment[2].price = this.thrPay;
          this.repayment[3].price = this.fourPay;
          this.repaymentshow = this.repayment;
        } else {
          this.repaymentshow = {};
        }
      } else {
        this.repaymentshow = {};
      }
    },

    // 金融服务ele
    elechoosePriceServer(index) {
      this.active = index;
      if (index == 0) {
        this.elepriceServer = true;
      } else {
        this.elepriceServer = false;
      }
    },
    // 选择版本
    async elechooseServer(index) {
      if (this.serverNum == index) {
        this.current = -1;
        this.paymentNum = -1;
        this.stagesNum = -1;
        this.serverNum = -1;
        this.allprice = Number(this.price)
        await this.elepriceJudge();
      } else {
        this.serverNum = index;
        this.allprice =
          Math.round(Number(this.price) * (1 + 0.04) * 10000) / 10000;
        await this.elepriceJudge();
      }
    },
    // 选择首付
    elechoosePayment(item, index) {
      if (this.paymentNum == index) {
        this.paymentNum = -1;
        this.stagesNum = -1;
        console.log(this.stagesNum, "分期index");
        console.log(this.paymentNum, "首付index");
        console.log(this.allprice, "当前总价");
        this.elepriceJudge();
      } else {
        this.paymentNum = index;
        console.log(this.stagesNum, "分期index");
        console.log(this.paymentNum, "首付index");
        console.log(this.allprice, "当前总价");
        this.elepriceJudge();
      }
    },
    // 选择分期
    elechooseStages(item, index) {
      if (this.stagesNum == index) {
        this.stagesNum = -1;
        console.log(this.stagesNum, "分期index");
        console.log(this.paymentNum, "首付index");
        console.log(this.allprice, "当前总价");
        this.elepriceJudge();
      } else {
        this.stagesNum = index;
        console.log(this.stagesNum, "分期index");
        console.log(this.paymentNum, "首付index");
        console.log(this.allprice, "当前总价");
        this.elepriceJudge();
      }
    },
    // 价格判断
    elepriceJudge() {
      if (this.paymentNum == 0) {
        if (this.stagesNum == 0) {
          this.onePay = Math.round(Number(this.allprice) * 879.11);
          this.repayment[0].price = this.onePay;
          this.repaymentshow = this.repayment.slice(0, 1);
        } else if (this.stagesNum == 1) {
          this.onePay = Math.round(Number(this.allprice) * 461.45);
          this.twoPay = Math.round(Number(this.allprice) * 461.45);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repaymentshow = this.repayment.slice(0, 2);
        } else if (this.stagesNum == 2) {
          this.onePay = Math.round(Number(this.allprice) * 335.36);
          this.twoPay = Math.round(Number(this.allprice) * 335.36);
          this.thrPay = Math.round(Number(this.allprice) * 293.01);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repayment[2].price = this.thrPay;
          this.repaymentshow = this.repayment.slice(0, 3);
        } else if (this.stagesNum == 3) {
          this.onePay = Math.round(Number(this.allprice) * 272.39);
          this.twoPay = Math.round(Number(this.allprice) * 272.39);
          this.thrPay = Math.round(Number(this.allprice) * 240.62);
          this.fourPay = Math.round(Number(this.allprice) * 219.79);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repayment[2].price = this.thrPay;
          this.repayment[3].price = this.fourPay;
          this.repaymentshow = this.repayment;
        } else {
          this.repaymentshow = {};
        }
      } else if (this.paymentNum == 1) {
        if (this.stagesNum == 0) {
          this.onePay = Math.round(Number(this.allprice) * 0.7 * 879.11);
          this.repayment[0].price = this.onePay;
          this.repaymentshow = this.repayment.slice(0, 1);
        } else if (this.stagesNum == 1) {
          this.onePay = Math.round(Number(this.allprice) * 0.7 * 461.45);
          this.twoPay = Math.round(Number(this.allprice) * 0.7 * 461.45);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repaymentshow = this.repayment.slice(0, 2);
        } else if (this.stagesNum == 2) {
          this.onePay = Math.round(Number(this.allprice) * 0.7 * 335.36);
          this.twoPay = Math.round(Number(this.allprice) * 0.7 * 335.36);
          this.thrPay = Math.round(Number(this.allprice) * 0.7 * 293.01);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repayment[2].price = this.thrPay;
          this.repaymentshow = this.repayment.slice(0, 3);
        } else if (this.stagesNum == 3) {
          this.onePay = Math.round(Number(this.allprice) * 0.7 * 272.39);
          this.twoPay = Math.round(Number(this.allprice) * 0.7 * 272.39);
          this.thrPay = Math.round(Number(this.allprice) * 0.7 * 240.62);
          this.fourPay = Math.round(Number(this.allprice) * 0.7 * 219.79);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repayment[2].price = this.thrPay;
          this.repayment[3].price = this.fourPay;
          this.repaymentshow = this.repayment;
        } else {
          this.repaymentshow = {};
        }
      } else if (this.paymentNum == 2) {
        if (this.stagesNum == 0) {
          this.onePay = Math.round(Number(this.allprice) * 0.5 * 879.11);
          this.repayment[0].price = this.onePay;
          this.repaymentshow = this.repayment.slice(0, 1);
        } else if (this.stagesNum == 1) {
          this.onePay = Math.round(Number(this.allprice) * 0.5 * 461.45);
          this.twoPay = Math.round(Number(this.allprice) * 0.5 * 461.45);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repaymentshow = this.repayment.slice(0, 2);
        } else if (this.stagesNum == 2) {
          this.onePay = Math.round(Number(this.allprice) * 0.5 * 335.36);
          this.twoPay = Math.round(Number(this.allprice) * 0.5 * 335.36);
          this.thrPay = Math.round(Number(this.allprice) * 0.5 * 293.01);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repayment[2].price = this.thrPay;
          this.repaymentshow = this.repayment.slice(0, 3);
        } else if (this.stagesNum == 3) {
          this.onePay = Math.round(Number(this.allprice) * 0.5 * 272.39);
          this.twoPay = Math.round(Number(this.allprice) * 0.5 * 272.39);
          this.thrPay = Math.round(Number(this.allprice) * 0.5 * 240.62);
          this.fourPay = Math.round(Number(this.allprice) * 0.5 * 219.79);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repayment[2].price = this.thrPay;
          this.repayment[3].price = this.fourPay;
          this.repaymentshow = this.repayment;
        } else {
          this.repaymentshow = {};
        }
      } else if (this.paymentNum == 3) {
        if (this.stagesNum == 0) {
          this.onePay = Math.round(Number(this.allprice) * 0.3 * 879.11);
          this.repayment[0].price = this.onePay;
          this.repaymentshow = this.repayment.slice(0, 1);
        } else if (this.stagesNum == 1) {
          this.onePay = Math.round(Number(this.allprice) * 0.3 * 461.45);
          this.twoPay = Math.round(Number(this.allprice) * 0.3 * 461.45);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repaymentshow = this.repayment.slice(0, 2);
        } else if (this.stagesNum == 2) {
          this.onePay = Math.round(Number(this.allprice) * 0.3 * 335.36);
          this.twoPay = Math.round(Number(this.allprice) * 0.3 * 335.36);
          this.thrPay = Math.round(Number(this.allprice) * 0.3 * 293.01);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repayment[2].price = this.thrPay;
          this.repaymentshow = this.repayment.slice(0, 3);
        } else if (this.stagesNum == 3) {
          this.onePay = Math.round(Number(this.allprice) * 0.3 * 272.39);
          this.twoPay = Math.round(Number(this.allprice) * 0.3 * 272.39);
          this.thrPay = Math.round(Number(this.allprice) * 0.3 * 240.62);
          this.fourPay = Math.round(Number(this.allprice) * 0.3 * 219.79);
          this.repayment[0].price = this.onePay;
          this.repayment[1].price = this.twoPay;
          this.repayment[2].price = this.thrPay;
          this.repayment[3].price = this.fourPay;
          this.repaymentshow = this.repayment;
        } else {
          this.repaymentshow = {};
        }
      } else {
        this.repaymentshow = {};
      }
    },
  },
};
</script>

<style lang="less" scoped src="./index.less"></style>
