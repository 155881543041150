<template>
  <div class="traceability">
    <div class="commen-title">
      <div class="commen-box">
        <div class="left">
          <div class="left-sign"></div>
          <div class="left-title">严格品控</div>
        </div>
      </div>
    </div>

    <div class="traceability-box">
      <div
        class="traceability-item"
        v-for="(item, index) in qualityChase"
        :key="index"
        @click="goTracDetail(index)"
      >
        <div class="traceability-item-left">
          <div class="traceability-img">
            <!-- <img src alt /> -->
          </div>
          <div class="traceability-title" v-html="item.msg"></div>
          <div class="traceability-detail" v-if="item.icon">></div>
        </div>
        <div class="traceability-item-right">
          <img src="../../../../static/detail/arrow.png" alt />
        </div>
      </div>
    </div>

    <div class="check">*中国汽车流通协会授权单位对悦心车车辆进行抽检</div>

    <!-- dallog record -->
    <el-dialog title="维修保养记录" :visible.sync="record" width="600px">
      <div style="text-align:center" v-if="details.length == '0'">
        该车目前暂无（出险、保养）记录
      </div>
      <div class="record-box" v-else>
        <div class="record-wraper" style="overflow:auto">
          <div
            class="record-list"
            v-for="(item, index) in details"
            :key="index"
          >
            <div class="record-list-left-box">
              <div class="record-list-left-time">{{ item.service_time }}</div>
              <div class="record-list-left-kl">{{ item.car_km }}</div>
            </div>
            <div class="record-line"></div>
            <div class="record-list-right-box">
              <div class="record-list-right-ball"></div>
              <div class="record-list-right-con">
                <div class="record-list-right-con-title">
                  {{ item.service_type }}
                </div>
                <div class="record-list-right-con-msg">
                  {{ item.service_detail }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- dallog record -->
    <!-- <el-dialog title="维修保养记录" :visible.sync="record" width="600px">
      <div style="text-align:center" v-if="details.length == '0'">
        该车目前暂无（出险、保养）记录
      </div>
      <div class="record-box" v-else>
        <div class="record-wraper" style="overflow:auto">
          <div
            class="record-list"
            v-for="(item, index) in details"
            :key="index"
          >
            <div class="record-list-left-box">
              <div class="record-list-left-time">{{ item.service_time }}</div>
              <div class="record-list-left-kl">{{ item.car_km }}</div>
            </div>
            <div class="record-line"></div>
            <div class="record-list-right-box">
              <div class="record-list-right-ball"></div>
              <div class="record-list-right-con">
                <div class="record-list-right-con-title">
                  {{ item.service_type }}
                </div>
                <div class="record-list-right-con-msg">
                  {{ item.service_detail }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog> -->

    <!-- 整车检测报告 -->
    <div class="info">
      <el-dialog  :visible.sync="report" width="1000px">
        <div class="report-header" slot="title">
          <div class="report-header-msg" style="font-size: 24px;line-height: 66px;">
            整车检测报告
          </div>
        </div>
        <div style="text-align:center" v-if="details.length == '0'">
          <img
            v-if="this.pc_pop1"
            style="width:100%;display:inline-block"
            :src="this.pc_pop1"
            alt=""
          />
          <div v-else>暂无检测报告</div>
        </div>
      </el-dialog>
    </div>

    <!-- 保养 -->
    <div class="info">
      <el-dialog  :visible.sync="maintenance" width="1000px">
        <div class="report-header" slot="title">
          <div class="report-header-msg" style="font-size: 24px;line-height: 66px;">
            整车专业整备及深度保养
          </div>
        </div>
        <div style="text-align:center" v-if="details.length == '0'">
          <img
            v-if="this.pc_pop2"
            style="width:100%;display:inline-block"
            :src="this.pc_pop2"
            alt=""
          />
          <div v-else style="margin-top:200px">暂无专业整备及深度保养</div>
        </div>
      </el-dialog>
    </div>

    <!-- dialog -->
    <div class="info">
      <el-dialog  :visible.sync="check" width="1000px">
        <div class="report-header" slot="title">
          <div class="report-header-msg" style="font-size: 24px;">
            {{ checkReport.title }}
          </div>
          <div style="font-size: 14px;margin-top: 12px;font-weight:bold;">
            {{ checkReport.entitle }}
          </div>
        </div>
        <div class="report-wraper">
          <div class="report-con">
            <div class="report-con-info">
              <div class="report-con-info-title">
                {{ checkReport.contitle }}
              </div>
              <div class="report-con-info-con">
                {{ checkReport.contitlemsg }}
              </div>
            </div>
            <div class="report-con-res">
              <div class="report-con-res-title">{{ checkReport.restitle }}</div>
              <div class="report-con-res-con">
                {{ checkReport.restitlemsg }}
              </div>
            </div>
            <div class="report-con-person">
              <div class="report-con-person-avatar-wraper">
                <div class="report-con-person-avatar">
                  <img
                    v-if="baseurl + checkReport.personavatar"
                    :src="baseurl + checkReport.personavatar"
                    alt
                  />
                </div>
              </div>

              <div class="report-con-person-info">
                <div class="report-con-person-info-name">
                  检测员:
                  <span>{{ checkReport.personname }}</span>
                </div>
                <div class="report-con-person-info-time">
                  检测时间：
                  <span>{{ checkReport.persontime }}</span>
                </div>
              </div>
            </div>
            <div class="report-con-prove">
              <div class="report-con-prove-logo"></div>
              <div class="report-con-prove-componey">
                {{ checkReport.conponeny }}
                <div class="report-con-prove-componey-section"></div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { carDetail } from "@/api/index.js";
export default {
  data() {
    return {
      pc_pop1:'',
      pc_pop2:'',
      id: "",
      baseurl: "https://www.yuexinche.com",
      details: [],
      check: false,
      record: false,
      report: false,  //整车报告弹框
      maintenance:false,  //保养弹框
      // 质量追溯
      qualityChase: [
        {
          msg: "整车<br>检测报告",
          icon: true,
        },
        {
          msg: "整车<br>专业整备及深度保养",
          icon: true,
        },
        // {
        //   msg: "整车专业<br>整备及保养",
        //   icon: false
        // },
        // {
        //   msg: "整车深度<br>清洁美容及消毒",
        //   icon: false
        // },
        {
          msg: "整车<br>上架前检测",
          icon: true,
        },
        {
          msg: "权威<br>机构抽检",
          icon: false,
        },
      ],
      checkReport: {
        title: "悦心车售前检测报告",
        entitle: "Pre Delivery Inspection Report",
        contitle: "车辆信息",
        contitlemsg: "",
        restitle: "检测结果",
        restitlemsg:
          "该车排除重大事故、火烧、涉水情况。发动机舱内部机器部件正常。无拆卸渗漏等痕迹，变速箱工况正常，怠速规律无抖动，转向灵活且无异常。电子系统一切正常。内饰整洁，无磨损。全车检测完毕可享受悦心车所有保障和权益。检测详情可查看检测报告。",
        personavatar: "",
        personname: "",
        persontime: "",
        conponeny: "北京华奥汽车服务股份有限公司悦心车旧机动车经销分公司",
      },
    };
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.carDetail(); //车辆详情
    // this.load(); //维修记录   改版弃用
  },
  methods: {
    // 车辆详情
    carDetail() {
      carDetail({
        id: this.id,
      }).then((res) => {
        this.checkReport.contitlemsg = `${res.data.car_name} ${res.data.displacement}`;
        this.checkReport.personavatar = res.data.inspector_avatar;
        this.checkReport.personname = res.data.inspector_name || null;
        this.checkReport.persontime = res.data.inspect_time || null;
        this.pc_pop1 = res.data.pc_pop1;
        this.pc_pop2 = res.data.pc_pop2;
      });
    },
    // 质量追溯详情或者dialog
    goTracDetail(index) {
      if (index == 0) {
        // this.record = true; //维修记录   改版弃用
        this.report = true;
      } else if (index == 1) {
        // this.$router.push({    // 改版弃用
        //   path: "/detail/360check",
        //   query: { id: this.id },
        // });
        this.maintenance = true
      } else if (index == 2) {
        this.check = true;
      }
    },
    // 维修记录
    // load() {
    //   getAllUpkeepRec({
    //     id: this.id, //测试是2
    //     p: 1,
    //   }).then((res) => {
    //     this.details = res.data;
    //   });
    // },
  },
};
</script>

<style lang="less" scoped>
.traceability {
  width: 100%;
  // height: 650px;
  border: 1px solid #f4f4f3;
  margin-top: 40px;
  padding: 0 10px 40px;
  box-sizing: border-box;
  background: #f5f9fc;
  .info {
    /deep/ .el-dialog {
      border-radius: 20px;
      .el-dialog__header {
        padding: 0;
      }
    }
  }

  // 维修记录
  .record-box {
    width: 100%;
    height: 500px;
    background-color: #fff;
    .record-wraper {
      width: 100%;
      height: 100%;
      background-color: #fff;
      .record-list {
        width: 100%;
        display: flex;
        margin-bottom: 30px;
        position: relative;
        .record-list-left-box {
          width: 25%;
          text-align: center;
          font-size: 14px;
          color: #666666;
        }
        .record-line {
          position: absolute;
          width: 2px;
          height: calc(100% + 30px);
          left: 27.5%;
          top: 15px;
          background-color: #eee;
        }

        &:last-child {
          .record-line {
            display: none;
          }
        }
        .record-list-right-box {
          display: flex;
          width: 75%;
          font-size: 14px;
          color: #333333;
          .record-list-right-ball {
            z-index: 99;
            width: 10px;
            height: 10px;
            background-color: #0097e2;
            border-radius: 50%;
            margin-top: 10px;
            margin-left: 10px;
          }
          .record-list-right-con {
            width: 100%;
            margin-left: 10px;
          }
        }
      }
    }
  }

  // 弹框
  .report-header {
    width: 100%;
    height: 120px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-image: url(https://www.yuexinche.com/pic/pc9.png);
    background-size: 100% 100%;
    padding: 26px 21px 18.5px;
    box-sizing: border-box;
    color: #fff;
  }
  .report-wraper {
    width: 100%;
    height: auto;
    background-color: #fff;
    padding: 0 50px;

    .report-con {
      width: 100%;
      height: auto;
      // padding: 0 21px;
      box-sizing: border-box;

      .report-con-info {
        padding: 17px 0 13px;
        box-sizing: border-box;
        border-bottom: 1px solid #b2cde2;

        .report-con-info-title {
          font-size: 18px;
          color: #3a3a3a;
        }

        .report-con-info-con {
          margin-top: 15px;
          font-size: 16px;
          color: #717171;
        }
      }

      .report-con-res {
        padding: 17px 0 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #b2cde2;

        .report-con-res-title {
          font-size: 18px;
          color: #3a3a3a;
        }

        .report-con-res-con {
          margin-top: 20px;
          font-size: 16px;
          color: #717171;
        }
      }

      .report-con-person {
        width: 100%;
        height: 100px;
        padding: 14px 0;
        box-sizing: border-box;
        border-bottom: 1px solid #b2cde2;
        display: flex;
        align-items: center;

        .report-con-person-avatar-wraper {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #0059a0;

          .report-con-person-avatar {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .report-con-person-avatar > img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .report-con-person-info {
          margin-left: 20px;
          width: auto;
          font-size: 16px;
          color: #717171;
          line-height: 15px;

          .report-con-person-info-time {
            margin-top: 4px;
          }
        }
      }

      .report-con-prove {
        width: 100%;
        height: 110px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        .report-con-prove-logo {
          width: 100px;
          height: 40px;
          background-image: url(https://www.yuexinche.com/pic/logo.png);
          background-size: 100% 100%;
        }

        .report-con-prove-componey {
          font-size: 16px;
          color: #717171;
          position: relative;

          .report-con-prove-componey-section {
            position: absolute;
            width: 105px;
            height: 105px;
            right: 5px;
            top: calc(50% - 53px);
            background-image: url(https://www.yuexinche.com/pic/12.png);
            background-size: 100% 100%;
          }
        }
      }
    }

    .report-footer {
      width: 100%;
      height: 5px;
      background-color: #0059a0;
    }
  }

  // 公共头部
  .commen-title {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    .commen-box {
      display: flex;
      height: 60px;
      border-bottom: 1px solid #eee;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        align-content: center;
        height: 25px;
        line-height: 25px;
        font-size: 20px;
        color: #333;
        .left-sign {
          width: 4px;
          height: 100%;
          background-color: #0097e2;
          margin-right: 20px;
        }
      }
    }
  }

  .traceability-box {
    margin: 0 auto;
    display: flex;
    padding: 70px 90px 30px;
    justify-content: space-around;
    // flex-wrap: wrap;
    .traceability-item {
      display: flex;
      .traceability-item-left {
        .traceability-img {
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: url("../../../../static/detail/zhui2.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 50px 50px;
          // background-color: blue;
          // img {
          //   max-width: 100%;
          //   max-height: 100%;
          // }
        }
        .traceability-title {
          margin-top: 25px;
          font-size: 14px;
          color: #666666;
          text-align: center;
          line-height: 24px;
        }
        .traceability-detail {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          border: 1px solid #ddd;
          color: #ddd;
          // opacity: 0.2;
          text-align: center;
          // justify-content: center;
          margin: 20px auto 0;
          line-height: 20px;
        }
      }
      .traceability-item-right {
        margin-left: 50px;
        width: 105px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      &:nth-child(2) {
        .traceability-img {
          background-image: url("../../../../static/detail/zhui3.png");
          background-size: 61px 44px;
        }
      }
      &:nth-child(3) {
        .traceability-img {
          background-image: url("../../../../static/detail/zhui5.png");
          background-size: 47px 47px;
        }
      }
      &:nth-child(4) {
        .traceability-item-right {
          display: none;
        }
        .traceability-img {
          background-image: url("../../../../static/detail/zhui6.png");
          background-size: 52px 38px;
        }
      }
      &:nth-child(5) {
        .traceability-item-right {
          display: none;
        }
        .traceability-img {
          background-image: url("../../../../static/detail/zhui5.png");
          background-size: 45px 45px;
        }
      }
      &:nth-child(6) {
        .traceability-item-right {
          display: none;
        }
        .traceability-img {
          background-image: url("../../../../static/detail/zhui6.png");
          background-size: 43px 43px;
        }
      }
    }
    .traceability-item:hover {
      .traceability-detail {
        background-color: #0097e2;
        color: #fff;
      }
    }
  }

  .check {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: #fff;
    font-size: 14px;
    color: #d6342e;
    text-align: center;
    border: 1px solid rgba(244, 244, 244, 1);
    border-radius: 4px;
  }
}
</style>
